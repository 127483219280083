import { gql } from "@apollo/client"

const CHECKOUT_MUTATION = gql`
  mutation CHECKOUT_MUTATION($input: CheckoutInput!) {
    checkout(input: $input) {
      order {
        id
        cartHash
        databaseId
        orderKey
        orderNumber
        paymentMethod
        total
        shippingTotal
        customer {
          email
        }
        lineItems {
          nodes {
            quantity
            total
            totalTax
            product {
              databaseId
              name
              paProducents {
                nodes {
                  name
                }
              }
              productCategories {
                nodes {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CHECKOUT_MUTATION
