import "./order-nav.scss"

import React from "react"

import IcoOrderStep1 from "assets/images/ico-order-step-1.svg"
import IcoOrderStep2 from "assets/images/ico-order-step-2.svg"
import IcoOrderStep3 from "assets/images/ico-order-step-3.svg"
import IcoOrderStep4 from "assets/images/ico-order-step-4.svg"

const OrderNav = ({ step }) => {
  return (
    <div className="order__nav">
      <div className={`order__nav-item${step === 1 ? "--current" : ""}`}>
        <div className="order__nav-image">
          <img src={IcoOrderStep1} alt="" />
        </div>
        <p>Koszyk</p>
      </div>
      <span className="order__nav-arrow"></span>
      <div className={`order__nav-item${step === 2 ? "--current" : ""}`}>
        <div className="order__nav-image">
          <img src={IcoOrderStep2} alt="" />
        </div>
        <p>Dostawa i płatność</p>
      </div>
      <span className="order__nav-arrow"></span>
      <div className={`order__nav-item${step === 3 ? "--current" : ""}`}>
        <div className="order__nav-image">
          <img src={IcoOrderStep3} alt="" />
        </div>
        <p>Podsumowanie</p>
      </div>
      <span className="order__nav-arrow"></span>
      <div className={`order__nav-item${step === 4 ? "--current" : ""}`}>
        <div className="order__nav-image">
          <img src={IcoOrderStep4} alt="" />
        </div>
        <p>Zamówienie złożone</p>
      </div>
    </div>
  )
}

export default OrderNav
